<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('jobs.tablePageTitle') | title" />

    <v-card class="mx-4 status-table">
      <v-data-table
        id="jobStatusTable"
        class="elevation-1"
        :headers="jobStatusHeaders"
        :items="jobStatuses"
        disable-pagination
        hide-default-footer
      ></v-data-table>
    </v-card>

    <huboo-table
      id="jobTable"
      @filter="handleFilter"
      @row-clicked="onSelected"
      @search="handleSearch"
      @update:options="handleUpdateOptions"
      :filters="filters"
      :headers="headers"
      :items="items"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :server-items-length="meta.total"
      clickable
      hide-search
      v-bind="options"
    >
    </huboo-table>
  </huboo-page>
</template>

<script>
import i18n from '@/boot/i18n'
import { title } from '@/utilities/filters'
import { mapActions, mapGetters } from 'vuex'
import { handleSearch } from '@/views/pages/billing/billingViewHelpers.js'
import { isLoading } from '@/utilities/helpers/views'
import ClientMixin from '@/mixins/client'

const tableHeaders = [
  {
    text: title(i18n.t('jobs.tableHeaders.id')),
    sortable: false,
    value: 'id',
  },
  {
    text: title(i18n.t('jobs.tableHeaders.queue')),
    sortable: false,
    value: 'attributes.queue',
  },
  {
    text: title(i18n.t('jobs.tableHeaders.attempts')),
    sortable: false,
    value: 'attributes.attempts',
  },
  {
    text: title(i18n.t('jobs.tableHeaders.reservedAt')),
    sortable: true,
    value: 'attributes.reservedAt',
  },
  {
    text: title(i18n.t('jobs.tableHeaders.availableAt')),
    sortable: true,
    value: 'attributes.availableAt',
  },
  {
    text: title(i18n.t('jobs.tableHeaders.createdAt')),
    sortable: true,
    value: 'attributes.createdAt',
  },
  {
    text: title(i18n.t('jobs.tableHeaders.payload')),
    sortable: false,
    value: 'attributes.payload',
  },
]

export default {
  name: 'Jobs',
  mixins: [ClientMixin],
  components: {},
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    search: '',
    selected: null,
  }),
  computed: {
    ...mapGetters('core', ['getIsAdmin']),
    ...mapGetters('jobs', ['getJobsStatus']),
    isAdmin() {
      return this.getIsAdmin
    },
    getJobs() {
      return this.$store.getters['jobs/getAll']
    },
    headers() {
      return tableHeaders
    },
    jobStatusHeaders() {
      return [
        {
          text: title(i18n.t('jobs.statuses.jobName')),
          sortable: false,
          value: 'jobName',
        },
        {
          text: title(i18n.t('jobs.statuses.labels.new')),
          sortable: false,
          value: 'newStatus',
        },
        {
          text: title(i18n.t('jobs.statuses.labels.pending')),
          sortable: false,
          value: 'pendingStatus',
        },
        {
          text: title(i18n.t('jobs.statuses.labels.processing')),
          sortable: false,
          value: 'processingStatus',
        },
      ]
    },
    jobStatuses() {
      return this.getJobsStatus || []
    },
    items() {
      return this.jobs
    },
    loading() {
      return isLoading(this.url)
    },
    getMeta() {
      return this.$store.getters['jobs/getMeta']
    },
    meta() {
      return { total: this.getMeta.total || this.items.length }
    },
    jobs() {
      const lists = this.getJobs
      if (Array.isArray(lists))
        return lists.map(l => ({
          ...l,
          loading: this.removeLoading(l.id),
        }))
      else return []
    },
    url() {
      return this.urlEntity
    },
    urlEntity() {
      return '/jobs/'
    },
  },
  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    await this.fetchJobsStatus()
  },
  methods: {
    ...mapActions('jobs', ['fetchJobsStatus']),
    fetch() {
      const { options, search } = this
      const { page, itemsPerPage } = options
      const params = {}
      if (page) params.page = page
      if (itemsPerPage) params.itemsPerPage = itemsPerPage
      if (search) params.query = search
      return this.$store.dispatch('jobs/fetchCollection', { params })
    },
    handleSearch(e) {
      return handleSearch(this, e)
    },
    onSelected(e) {
      // disable select if not admin
      const selectedArray = Object.entries(e)
      const stringToBool = selectedArray.map(item => {
        if (item[1] === 'Yes') {
          return [item[0], true]
        }
        if (item[1] === 'No') {
          return [item[0], false]
        }
        return item
      })

      this.selected = stringToBool.reduce((acc, item) => {
        acc[item[0]] = item[1]
        return acc
      }, {})
      this.editModal = true
    },

    onUpdatedItem() {
      this.fetch()
    },

    removeLoading(id) {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: this.urlEntity + id,
        method: 'DELETE',
      })
    },
    handleUpdateOptions(newOptions) {

      this.options = {
        ...this.options,
        page: newOptions.page,
        itemsPerPage: newOptions.itemsPerPage,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .status-table {
  tbody tr:hover {
    background-color: transparent !important;
  }
}
</style>
